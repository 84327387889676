import React from "react";
import {
  useTranslate,
  Create,
  SaveButton,
  Toolbar,
  SimpleForm,
  TextInput,
  SelectInput,
  required
} from "react-admin";

import JsonInput from "../AlembicJsonFields/JsonInput";
import { makeStyles } from "@mui/styles";

import Aside from "./Aside";

import ConfigDefaultScopeChoices from "./ConfigDefaultScopeChoices";
import { Typography } from "@mui/material";

const useToolbarStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const ConfigDefaultCreateToolbar = (props) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar {...props} classes={classes}>
      <SaveButton />
    </Toolbar>
  );
};

const handleTransform = (data) => {
  const final = data;
  if (data.config_key) {
    final.config_key = data.config_key.toLowerCase();
  }
  return final;
};

const ConfigDefaultCreate = ({ children, ...props }) => {
  const translate = useTranslate();

  return (
    <Create
      {...props}
      aside={<Aside />}
      title={translate("config_defaults.create.title")}
      toolbar={<ConfigDefaultCreateToolbar />}
      transform={handleTransform}
    >
      <SimpleForm>
        <Typography variant="caption">
          {translate("config_defaults.field_help.scope")}
        </Typography>
        <SelectInput source="scope" fullWidth validate={required()} choices={ConfigDefaultScopeChoices} />

        <Typography variant="caption">
          {translate("config_defaults.field_help.config_key")}
        </Typography>
        <TextInput source="config_key"
          label={translate("config_defaults.field_labels.config_key")}
          fullWidth
          validate={required()}
          format={(v) => v ? v.toLowerCase() : ''} />

        <Typography variant="caption">
          {translate("config_defaults.field_help.description")}
        </Typography>
        <TextInput source="description" fullWidth validate={required()} />

        <Typography variant="caption">
          {translate("config_defaults.field_help.config_value")}
        </Typography>
        <JsonInput source="config_value" isRequired />
        <br />
        <br />
      </SimpleForm>
    </Create >
  );
};

export default ConfigDefaultCreate;
