/* eslint-disable react/forbid-prop-types */
/* eslint react/jsx-key: off */
import React from "react";

import {
  useTranslate,
  Edit,
  TextInput,
  SaveButton,
  DeleteWithConfirmButton,
  Toolbar,
  useRedirect,
  useNotify,
  useRefresh,
  SimpleForm,
  SelectInput,
  required
} from "react-admin";

import { Typography } from "@mui/material";
import ConfigDefaultTitle from "./ConfigDefaultTitle";
import ConfigDefaultScopeChoices from "./ConfigDefaultScopeChoices";

import JsonInput from "../AlembicJsonFields/JsonInput";
import { makeStyles } from "@mui/styles";

import Aside from "./Aside";

const useToolbarStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

/**
 * Custom Toolbar for the Edit form
 *
 * Save with undo, but delete with confirm
 */
const ConfigDefaultEditToolbar = (props) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar {...props} classes={classes}>
      <SaveButton />
      <DeleteWithConfirmButton redirect="/Configdefaults" />
    </Toolbar>
  );
};

const handleTransform = (data) => {
  const final = data;
  if (data.config_key) {
    final.config_key = data.config_key.toLowerCase();
  }
  return final;
};

const ConfigDefaultEdit = ({ permissions, ...props }) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();

  const onSuccess = (data) => {
    notify(`Changes to Config Default "${data.id}" saved`);
    redirect("/Configdefaults");
    refresh();
  };

  return (
    <Edit
      {...props}
      title={<ConfigDefaultTitle edit={true} />}
      aside={<Aside />}
      mutationOptions={{ onSuccess }}
      mutationMode="pessimistic"
      toolbar={<ConfigDefaultEditToolbar />}
      transform={handleTransform}
    >
      <SimpleForm>
        <Typography variant="caption">
          {translate("config_defaults.field_help.scope")}
        </Typography>
        <SelectInput source="scope" fullWidth validate={required()} choices={ConfigDefaultScopeChoices} />

        <Typography variant="caption">
          {translate("config_defaults.field_help.config_key")}
        </Typography>
        <TextInput source="config_key"
          label={translate("config_defaults.field_labels.config_key")}
          fullWidth
          validate={required()}
          format={(v) => v ? v.toLowerCase() : ''} />

        <Typography variant="caption">
          {translate("config_defaults.field_help.description")}
        </Typography>
        <TextInput source="description" fullWidth validate={required()} />

        <Typography variant="caption">
          {translate("config_defaults.field_help.config_value")}
        </Typography>
        <JsonInput source="config_value" isRequired />
      </SimpleForm>
    </Edit >
  );
};

export default ConfigDefaultEdit;
