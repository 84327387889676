/* eslint react/jsx-key: off */
import React from "react";

import {
  Datagrid,
  List,
  DateField,
  TextField,
  TextInput,
  ShowButton,
  Pagination,
  DeleteButton,
  SelectInput,
  TopToolbar,
  FilterButton,
  SimpleShowLayout,
  ReferenceField
} from "react-admin";

import FullNameField from "../FullNameField";
import JsonField from "../AlembicJsonFields/JsonField";
import Aside from "./Aside";
import ConfigDefaultScopeChoices from "../ConfigDefault/ConfigDefaultScopeChoices";
import OverrideButtonWithDialog from "../ConfigDefault/OverrideButtonWithDialog";
import { Chip } from '@mui/material';

const QuickFilter = ({ label }) => {
  return <Chip sx={{ marginBottom: 1 }} label={label} />;
};
const configOverrideFilters = [
  <TextInput label="Search Values" source="q" alwaysOn />,
  <SelectInput label="Scope" source="scope" defaultValue="" choices={ConfigDefaultScopeChoices} />,
  <TextInput label="Config Key" source="config_key" defaultValue="" />,
  <TextInput label="Scope ID" source="scope_id" defaultValue="" />,
  <TextInput label="Reason" source="reason" defaultValue="" />,
  <QuickFilter source="history" label="Show History" defaultValue={true} />,
];

const ConfigOverridePagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const ConfigOverridePanel = () => (
  <SimpleShowLayout>
    <TextField source="version" label="Version" />
    <ReferenceField
      label="Created By"
      source="creator_id"
      reference="User"
      link="show"
    >
      <FullNameField />
    </ReferenceField>
    <DateField source="created_at" showTime />
    <TextField source="reason" label="Reason" />
    <JsonField source="config_value" label="Config Value" fullWidth />
  </SimpleShowLayout>
);

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
  </TopToolbar>
);

const ConfigOverrideList = ({ permissions, ...props }) => (
  <List
    {...props}
    filters={configOverrideFilters}
    actions={<ListActions />}
    sort={{
      field: "id",
      order: "ASC",
    }}
    aside={<Aside />}
    pagination={<ConfigOverridePagination />}>
    <Datagrid optimized expand={<ConfigOverridePanel />}>
      <TextField source="id" />
      <TextField source="version" />
      <TextField source="scope" />
      <TextField source="config_key" />
      <TextField source="scope_id" />
      <ShowButton label="Show" />
      <DeleteButton label="Delete" />
      <OverrideButtonWithDialog replaceMode={true} label="Replace" />
    </Datagrid>
  </List>
);

export default ConfigOverrideList;
